import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
 
//store
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const ChangePassword = Loadable(lazy(() => import("../views/pages/authentication/ChangePassword")));

//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);
 
//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);
//invoice
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);
 
//notification
const UsetNotification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
 
//audit trailas
const AuditTrails = Loadable(
  lazy(() => import("../views/audit/AuditTrails"))
);
 
 
//online order reports
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);
 
//pending Settilement
const PendingSettilement = Loadable(
  lazy(() => import("../views/report/settlement/PendingSettilement"))
);
 
//create
const CreateInvoice = Loadable(
  lazy(() => import("../views/report/settlement/CreateInvoice"))
);
const ListInvoice = Loadable(
  lazy(() => import("../views/report/settlement/ListInvoice"))
);
 
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/userupdate"))
);
 
const CreateTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/create"))
);
const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const BankDetails = Loadable(
  lazy(() => import("../views/bank/bank"))
)
const LedgerList = Loadable(
  lazy(() => import("../views/ledger/LedgerList"))
)
const CashBack = Loadable(
  lazy(() => import("../views/cashback/CashBack"))
)
const WalletUtilisation = Loadable(
  lazy(() => import("../views/wallet/WalletUtilisation"))
)
const RefundList = Loadable(
  lazy(() => import("../views/refund/RefundList"))
)
const AccountSummary = Loadable(
  lazy(() => import("../views/accounts/accountSummary/AccountSummary"))
)
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/store/list",
      element: <ListStore />,
    },
    
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ]
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ]
        },
        {
          path: "pending-settlement",
          element: <PendingSettilement />,
        },
        {
          path: "list-invoice",
          element: <ListInvoice />,
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
        {
          path: "onlineOrder",
          element: <OnlineOrder />,
        },
        {
          path: "create-invoice",
          element: <CreateInvoice />,
        },
      ],
    },
    {
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/audit-tails",
      element: <AuditTrails />,
    },
    {
      path: "/notification/view",
      element: <UsetNotification />,
    },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        },
        {
          path: "create",
          element: <CreateTaxInvoice />,
        },
      ],
    },
    {
      path: "/bankdetails/view",
      element: <BankDetails />,
    },
    {
      path: "/ledger",
      element: <LedgerList />,
    },
    {
      path: "/cash-back",
      element: <CashBack />,
    },
    {
      path: "/wallet-utilisation",
      element: <WalletUtilisation />,
    },
    {
      path: "/refund-Report",
      element: <RefundList />,
    },
    {
      path: "/account-summary",
      element: <AccountSummary />,
    },
  ],
};
 
export default MainRoutes; 