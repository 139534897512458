import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// project imports
import NavigationScroll from "./layout/NavigationScroll";
import Routes from "./routes";
import themes from "./themes";
import SnackBar from "./components/SnackBar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function App() {

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider
          theme={themes({
            borderRadius: 12,
            defaultId:"default",
            fontFamily: "'Roboto', sans-serif",
            isOpen: [],
            opened: true,
          })}
        >
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
        <SnackBar />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
